<script setup>
import { onMounted, onUnmounted, ref, watch } from 'vue'
import { gsap } from 'gsap'
import DownloadBOGX from 'src/components/DownloadBOGX'
import SocializeLinks from 'src/components/SocializeLinks'
import { useMusicStore } from 'src/stores/music'
import FloatingArrow from 'src/components/FloatingArrow'
import { scrollTriggerBottom } from '../constant.js'
import EventsBox from 'src/pages/home/components/EventsBox'
import MintPoster from './MintPoster/index.vue'
import { useRouter } from 'vue-router'
import { homeEventseDataStorage } from 'src/helpers/store'
import { GAME_BANEFITS_NAME } from 'src/constant'
import ComingSoon from './ComingSoon'
const emit = defineEmits(['about'])
const props = defineProps(['scrollTriggerDom'])
const router = useRouter()
const { buttonClickAudioPlay, buttonCaptureAudioPlay } = useMusicStore()
const xLogoDomRef = ref(null)
const eventsBoxShowRef = ref(!homeEventseDataStorage.value)
homeEventseDataStorage.value = 1
const downloadShowRef = ref(false)
const linksShowRef = ref(false)
const aboutShowRef = ref(false)
const mintShowRef = ref(false)
const aboutTranslateYRef = ref(-50)
const hasTweenEndRef = ref(true)
let tween, aboutTween
function initScroll() {
  const scrollTrigger = {
    ...scrollTriggerBottom,
    trigger: props.scrollTriggerDom,
  }
  // 底部logo
  tween = gsap.to(xLogoDomRef.value, {
    y: '165%',
    scrollTrigger,
    onStart() {
      hasTweenEndRef.value = false
      mintShowRef.value = false
      eventsBoxShowRef.value = false
    },
    onComplete() {
      hasTweenEndRef.value = true
    },
  })
  window.tween = tween
}
function initAboutTween() {
  aboutTween = gsap.to(aboutTranslateYRef, {
    paused: true,
    duration: 0.4,
    value: 30,
    onComplete() {
      if (aboutShowRef.value) {
        this.reverse()
      }
    },
    onReverseComplete() {
      if (aboutShowRef.value) {
        this.play()
      }
    },
  })
}
// function handleAbout() {
//   buttonClickAudioPlay()
//   emit('about')
// }
watch(aboutShowRef, value => {
  if (value) {
    aboutTween?.play()
  } else {
    aboutTween?.reverse()
  }
})
function goToMint() {
  buttonClickAudioPlay()
  window.open(import.meta.env.VITE_PFP_BASE_PATH + '/mint')
  mintShowRef.value = false
}
function goToMycollection() {
  buttonClickAudioPlay()
  router.push({
    name: GAME_BANEFITS_NAME,
  })
}
function goToEthereal() {
  buttonClickAudioPlay()
  window.open(import.meta.env.VITE_PFP_BASE_PATH + '/collection/ethereal')
}
function handleMintShow() {
  if (hasTweenEndRef.value) {
    mintShowRef.value = true
  }
}
onMounted(() => {
  initScroll()
  initAboutTween()
})
onUnmounted(() => {
  tween?.kill()
  aboutTween?.kill()
})
const mintShow = import.meta.env.VITE_MINT_START
const gameBanefitsShow = import.meta.env.VITE_GAME_BANEFITS_SHOW
</script>

<template>
  <div ref="xLogoDomRef" class="x_logo gray-color z-[2] flex">
    <div class="line"></div>
    <EventsBox v-model:show="eventsBoxShowRef" :show-arrow="false">
      <FloatingArrow
        v-slot="{ activate }"
        :show="eventsBoxShowRef"
        color="#E9B356"
        class="mx-5"
        :manual="true"
      >
        <span
          :style="{ color: activate ? '#E9B356' : 'inherit' }"
          class="gray-color mouse_pointer"
          @mouseenter="buttonCaptureAudioPlay"
          @click="buttonClickAudioPlay"
          >EVENTS</span
        >
      </FloatingArrow>
    </EventsBox>
    <div class="line"></div>
    <DownloadBOGX v-model:show="downloadShowRef" :show-arrow="false">
      <FloatingArrow
        v-slot="{ activate }"
        class="mx-5"
        :show="downloadShowRef"
        :manual="true"
      >
        <div class="mouse_pointer relative">
          <div
            class="hover_triangle left-50%"
            :style="`transform: translate(-50%, -50%) rotate(${
              downloadShowRef ? 360 : 180
            }deg); border-top-color: ${
              downloadShowRef ? '#bcc6cd' : '#757a7e'
            }`"
          ></div>
          <span
            :style="{ color: activate ? '#fff' : 'inherit' }"
            @mouseenter="buttonCaptureAudioPlay"
            @click="buttonClickAudioPlay"
            >DOWNLOAD</span
          >
        </div>
      </FloatingArrow>
    </DownloadBOGX>
    <div class="line"></div>
    <FloatingArrow v-slot="{ activate }" class="mx-5" v-if="gameBanefitsShow">
      <div
        :style="{ color: activate ? '#fff' : 'inherit' }"
        class="gray-color mouse_pointer"
        @mouseenter="buttonCaptureAudioPlay"
        @click="goToMycollection"
      >
        COLLECTION
      </div>
    </FloatingArrow>
    <ComingSoon v-else>COLLECTION</ComingSoon>
    <div class="line"></div>
    <img
      src="/src/asset/x_logo.webp"
      alt=""
      class="mx-5 h-[87.5px] w-[241.5px]"
    />
    <div class="line"></div>
    <MintPoster
      v-model:show="mintShowRef"
      :show-arrow="false"
      @go="goToMint"
      @mintShow="handleMintShow"
    >
      <FloatingArrow
        v-slot="{ activate }"
        class="mx-5"
        :show="mintShowRef"
        :manual="true"
      >
        <div>
          <div
            class="hover_triangle left-50%"
            :style="`transform: translate(-50%, -50%) rotate(${
              mintShowRef ? 360 : 180
            }deg); border-top-color: ${mintShowRef ? '#bcc6cd' : '#757a7e'}`"
          ></div>
          <div
            :style="{ color: activate ? '#fff' : 'inherit' }"
            class="gray-color mouse_pointer"
            @mouseenter="buttonCaptureAudioPlay"
          >
            MINT
          </div>
        </div>
      </FloatingArrow>
    </MintPoster>

    <div class="line"></div>
    <FloatingArrow v-slot="{ activate }" class="mx-5" v-if="mintShow">
      <div
        :style="{ color: activate ? '#fff' : 'inherit' }"
        class="gray-color mouse_pointer"
        @mouseenter="buttonCaptureAudioPlay"
        @click="goToEthereal"
      >
        ETHEREAL
      </div>
    </FloatingArrow>
    <ComingSoon v-else>ETHEREAL</ComingSoon>
    <div class="line"></div>
    <SocializeLinks v-model:show="linksShowRef">
      <FloatingArrow
        v-slot="{ activate }"
        class="mx-5"
        :manual="true"
        :show="linksShowRef"
      >
        <div class="mouse_pointer relative">
          <div
            class="hover_triangle left-50%"
            :style="`transform: translate(-50%, -50%) rotate(${
              linksShowRef ? 360 : 180
            }deg); border-top-color: ${linksShowRef ? '#bcc6cd' : '#757a7e'}`"
          ></div>
          <span
            class="mouse_pointer"
            :style="{ color: activate ? '#fff' : 'inherit' }"
            @mouseenter="buttonCaptureAudioPlay"
            @click="buttonClickAudioPlay"
            >LINKS</span
          >
        </div>
      </FloatingArrow>
    </SocializeLinks>
    <!-- <div class="line"></div> -->
    <!-- <FloatingArrow class="mx-5" v-model:show="aboutShowRef">
      <div class="mouse_pointer relative">
        <span
          class="mouse_pointer"
          :style="{ color: aboutShowRef ? '#fff' : 'inherit' }"
          @mouseenter="buttonCaptureAudioPlay"
          @click="handleAbout"
          >ABOUT</span
        >
        <div
          v-show="aboutShowRef"
          class="hover_triangle left-50%"
          :style="`transform: translate(-50%, ${aboutTranslateYRef}%) rotate(360deg); border-top-color: #E9CF88`"
        ></div>
      </div>
    </FloatingArrow> -->
    <div class="line"></div>
  </div>
</template>

<style scoped lang="scss">
.x_logo {
  @apply fixed bottom-10 items-center w-[110vw] translate-x-[-50%] left-50% 4xl:left-47%;

  .hover_triangle {
    @apply absolute;
    @include triangle;
  }

  .line {
    flex: 1;
    height: 1px;

    @extend .gray-bg;

    &:last-child,
    &:first-child {
      @apply grow-2 2xl:grow-6;
    }
  }

  span,
  a {
    @apply relative;
    @extend .fz-3;

    &.red {
      @extend .red-color;
    }

    // &:not(.red):hover {
    //   @apply text-white;

    //   &::after,
    //   &::before {
    //     @apply absolute;
    //     @extend .fz-2;
    //   }

    //   &::after {
    //     content: '>';
    //     transform: translateX(50%);
    //   }

    //   &::before {
    //     content: '<';
    //     transform: translateX(-160%);
    //   }
    // }
  }
}
</style>

import { ref, watch, computed } from 'vue'
import { useAsyncState } from '@vueuse/core'
import { useMessage } from 'naive-ui'
import { fetchSaleListInfo, fetchRoleList } from 'src/api'
import { errorCodeToMessage } from 'src/helpers/utils'
import { getReceiveJobName } from 'src/pages/myCollection/utils'
import { useUserInfoStore } from 'src/stores/user'
import { useLoginStore } from 'src/stores/user'
import { useNFTCard } from 'src/stores/nftCard'
import { getBlockchainErrorCode } from 'src/sdk/blockchain'
import { soulJade, soulJadePurchase } from 'src/helpers/blockchain'
import { storeToRefs } from 'pinia'

export function useImmutableIncome() {
  const { open: login } = useLoginStore()
  const { hasLogin } = storeToRefs(useLoginStore())
  const { userInfo } = storeToRefs(useUserInfoStore())
  const { selectIncomeRoleId } = storeToRefs(useNFTCard())
  const message = useMessage()
  const walletaddr = ref('')
  const productsList = ref([])
  const roleId = ref()
  const roleList = ref([])
  const orderData = ref({})
  const showRoleModal = ref(false)
  const selectId = ref(0)
  const { execute: executeList } = useAsyncState(
    async () => {
      const data = await fetchSaleListInfo({
        web_type: 'soulJade',
      })
      productsList.value = data
    },
    null,
    {
      immediate: false,
      onError(error) {
        console.log('error', error)
        errorCodeToMessage(error)
      },
    },
  )

  const { execute: executeRoleList } = useAsyncState(
    async () => {
      const data = await fetchRoleList()
      const list = data.RoleList.map(item => {
        return {
          ...item,
          label: getReceiveJobName(item.Job, item.Name),
          value: item.UserID,
        }
      })
      if (!selectIncomeRoleId.value) {
        if (list?.length > 1) {
          showRoleModal.value = true
        } else if (list?.length === 1) {
          roleId.value = list[0].UserID
        }
      } else {
        roleId.value = selectIncomeRoleId.value
      }
      roleList.value = [
        {
          label: 'Please select.',
          value: '',
        },
        ...list,
      ]
    },
    null,
    {
      immediate: false,
      onError(error) {
        console.log('error', error)
      },
    },
  )

  const roleName = computed(() => {
    const item = roleList.value?.find(item => item.UserID === roleId.value)
    return item?.label
  })

  function handleSelectHero() {
    const currentList = roleList.value?.slice(1)
    if (currentList?.length > 1) {
      showRoleModal.value = true
    } else if (currentList?.length === 1) {
      return
    } else {
      message.info('your dont have hero')
    }
  }

  function handleConfirm(val) {
    roleId.value = val
    selectIncomeRoleId.value = val
  }

  const { execute: handleBuy, isLoading: loading } = useAsyncState(
    async (data, chainId) => {
      selectId.value = data.id
      try {
        if (!hasLogin.value) {
          login()
          return
        }
        if (!chainId) {
          message.warning('Please select network')
          return
        }
        const { count, id, price } = data
        productsList.value.find(item => {
          if (item.id === id) {
            item.qty = count
            orderData.value = item
          }
        })
        await soulJade.mint(chainId, count, price)
        await soulJadePurchase.mint(chainId, id, count, roleId.value)
        message.success('Buy success, Check your in-game Mail')
      } catch (error) {
        // const code = getBlockchainErrorCode(error)
        console.log('handleMint', error.message)
        if (error.message === '40001')
          message.error(
            'execution reverted: ERC20: transfer amount exceeds balance',
          )
      } finally {
      }
    },
    null,
    {
      immediate: false,
      onError(error) {
        console.log(error)
        errorCodeToMessage(error)
      },
    },
  )

  const successList = ref([])
  function handleSuccess() {
    console.log(orderData.value)
    const list = [
      {
        name: orderData.value.name,
        image: orderData.value.picture,
        qty: orderData.value.qty,
      },
    ]
    successList.value = list
    showSuccessModal.value = true
  }

  watch(
    userInfo,
    data => {
      if (data?.AccountId) {
        executeRoleList()
      } else {
        selectIncomeRoleId.value = ''
        roleId.value = ''
        roleList.value = [
          {
            label: 'Please select.',
            value: '',
          },
        ]
      }
    },
    {
      immediate: false,
    },
  )
  executeList()
  return {
    walletaddr,
    productsList,
    showRoleModal,
    roleName,
    roleId,
    roleList,
    loading,
    selectId,
    executeList,
    handleSelectHero,
    handleConfirm,
    handleBuy,
    handleSuccess,
  }
}

<script setup>
import Table from './Table'
import { useHistoryTable } from './index'
import bian_copyImg from 'src/asset/softPledge/inventory/bian_copy.webp'
import bian_usedImg from 'src/asset/softPledge/inventory/bian_used.webp'
import Observe from 'src/components/Observe'
const props = defineProps({
  list: {
    type: Array,
    default() {
      return []
    },
  },
  hasNFT: Boolean,
})
const { listRef, handleCopy } = useHistoryTable(props)
</script>
<template>
  <Table>
    <thead>
      <tr>
        <th class="w-200px"><div class="text-center">TXHASH</div></th>
        <th class="w-200px"><div class="text-center">WALLET</div></th>
        <th><div class="text-center">USERNAME NAME</div></th>
        <th><div class="text-center">ITEM</div></th>
        <th><div class="text-center">ITEM COUNT</div></th>
        <th><div class="text-center">PRICE</div></th>
        <th><div class="text-center">TIME</div></th>
        <th><div class="text-center">STATUS</div></th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <template v-for="(item, index) in listRef" :key="index">
        <tr>
          <td class="w-200px">
            <div
              @click="handleCopy(item.txhash)"
              class="text-#B5DCEB mouse_pointer text-center w-200px px-4 ellipsis-1"
            >
              {{ item.txhash }}
            </div>
          </td>
          <td>
            <div
              @click="handleCopy(item.wallet)"
              class="text-center mouse_pointer text-#B5DCEB w-200px px-4 ellipsis-1"
            >
              {{ item.wallet }}
            </div>
          </td>
          <td>
            <div class="text-center text-#B5DCEB">
              {{ item.name }}
            </div>
          </td>
          <td>
            <div class="text-center text-#B5DCEB">{{ item.product_name }}</div>
          </td>
          <td>
            <div class="text-center text-#B5DCEB">
              {{ item.num }}
            </div>
          </td>
          <td>
            <div class="text-center text-#B5DCEB">
              {{ parseFloat(item.price)?.toFixed(2) }}
            </div>
          </td>
          <td>
            <div class="text-center text-#B5DCEB">
              {{ item.ctrea_time }}
            </div>
          </td>
          <td>
            <div class="text-center text-#B5DCEB">
              {{ item.state === 2 ? 'success' : 'failed' }}
            </div>
          </td>
        </tr>
      </template>
    </tbody>
  </Table>
</template>

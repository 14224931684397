import {
  writeContract,
  readContract,
  hasBlockchainLogin,
} from 'src/sdk/blockchain'
import {
  BaseBlockchain,
  baseBlockchainParams,
  blockchainBalanceFees,
  blockchainBindAddress,
  commonBlockchainParams,
  useDecorator,
} from './blockchain1'

export class SoulJadeStore extends BaseBlockchain {
  /**
   * mint
   */
  async mint(args) {
    await hasBlockchainLogin()
    return writeContract(args)
  }
  /**
   * 获取钱包token数量
   */
  async getBalances(args) {
    await hasBlockchainLogin()
    return this.readContract(args)
  }
}
const SoulJadeProty = SoulJadeStore.prototype
useDecorator(SoulJadeProty, 'mint', blockchainBalanceFees)
useDecorator(SoulJadeProty, 'mint', baseBlockchainParams)
useDecorator(SoulJadeProty, 'mint', commonBlockchainParams)

import abi from 'src/sdk/blockchain/abi/BogxMarket.json'
import { SoulJadeStore } from '../soulJade'
import { storeToRefs } from 'pinia'
import { useUserInfoStore } from 'src/stores/user'
import { accountRef } from 'src/sdk/blockchain'
import { parseUnits } from 'viem'
class SoulJadePurchase extends SoulJadeStore {
  constructor() {
    super()
    this.setAbi(abi)
  }
  async mint(chainId, itemId, number, roleId) {
    console.log(chainId, number)
    await super.mint({
      functionName: 'purchase',
      contractAddress: '0xA30815ee0C9A17662bf923C13789925412c3525c',
      accountAddress: accountRef.value?.address,
      chainId,
      args: [roleId, itemId, number],
    })
  }
}

export const soulJadePurchase = new SoulJadePurchase()

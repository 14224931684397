import abi from 'src/sdk/blockchain/abi/AirdropToken.json'
import { SoulJadeStore } from '../soulJade'
import { storeToRefs } from 'pinia'
import { useUserInfoStore } from 'src/stores/user'
import { accountRef } from 'src/sdk/blockchain'
import { parseUnits, formatEther } from 'viem'
class SoulJade extends SoulJadeStore {
  constructor() {
    super()
    this.setAbi(abi)
  }
  async mint(chainId, number, price) {
    const data = await super.getBalances({
      functionName: 'balanceOf',
      contractAddress: '0x29e7B8fBf06f095c75f154b7448d5CCaDe845186',
      accountAddress: accountRef.value?.address,
      chainId,
      args: [accountRef.value?.address],
    })
    const priceFormat = formatEther(data)
    if (priceFormat < +price * number) {
      throw new Error('40001')
    }
    // 将单价转换为 wei 单位
    const priceInWei = parseUnits(new String(price), 18) // ETH 转换为 wei

    // 计算总价 (priceInWei * quantity)
    const totalPriceInWei = priceInWei * BigInt(number)
    await super.mint({
      functionName: 'approve',
      contractAddress: '0x29e7B8fBf06f095c75f154b7448d5CCaDe845186',
      accountAddress: accountRef.value?.address,
      chainId,
      args: ['0xA30815ee0C9A17662bf923C13789925412c3525c', totalPriceInWei],
    })
  }
}

export const soulJade = new SoulJade()
